import { Component, OnInit } from '@angular/core';
import {finalize} from 'rxjs/operators';
import {Training} from '../classes/training';
import {HttpClient} from '@angular/common/http';
import {Types} from '../classes/types';

@Component({
  selector: 'app-trainingtypes',
  templateUrl: './trainingtypes.component.html',
  styleUrls: ['./trainingtypes.component.scss']
})
export class TrainingtypesComponent implements OnInit {
  loading = false;
  types: [Types];
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.loading = true;
    this.http.get('https://trainings-crm.borisgloger.com/api/website/training-types')
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((result: [Types]) => {
        console.log('result', result);
        this.types = result;
      });
  }

  myLevels(levels: [{ id: number, name: string }]): string {
    let levelstring = '';
    levels.forEach(value => {
      levelstring += value.id + ': ' + value.name + ', ';
    });
    return levelstring.substring(0, levelstring.length - 2);
  }

}
