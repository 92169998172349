<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Allgemeine Daten
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let item of RegistrationKeys">

      <mat-checkbox [(ngModel)]="formData[item.key]" *ngIf="item.type=='boolean'">{{item.key}}
      </mat-checkbox>

      <mat-form-field appearance="fill" [floatLabel]="true" *ngIf="item.type=='string'">
        <mat-label>{{item.key}}</mat-label>
        <input matInput placeholder="" [(ngModel)]="formData[item.key]">
      </mat-form-field>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Billing Adress
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let item of BillingAdressKeys">

      <mat-checkbox [(ngModel)]="formData.billingAddress[item.key]" *ngIf="item.type=='boolean'">{{item.key}}
      </mat-checkbox>

      <mat-form-field appearance="fill" [floatLabel]="true" *ngIf="item.type=='string'">
        <mat-label>{{item.key}}</mat-label>
        <input matInput placeholder="" [(ngModel)]="formData.billingAddress[item.key]">
      </mat-form-field>

    </div>
  </mat-expansion-panel>


  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Company
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let item of CompanyKeys">

      <mat-checkbox [(ngModel)]="formData.company[item.key]" *ngIf="item.type=='boolean'">{{item.key}}
      </mat-checkbox>

      <mat-form-field appearance="fill" [floatLabel]="true" *ngIf="item.type=='string'">
        <mat-label>{{item.key}}</mat-label>
        <input matInput placeholder="" [(ngModel)]="formData.company[item.key]">
      </mat-form-field>

    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Training
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let item of TrainingKeys">

      <mat-checkbox [(ngModel)]="formData.training[item.key]" *ngIf="item.type=='boolean'">{{item.key}}
      </mat-checkbox>

      <mat-form-field appearance="fill" [floatLabel]="true" *ngIf="item.type=='string'">
        <mat-label>{{item.key}}</mat-label>
        <input matInput placeholder="" [(ngModel)]="formData.training[item.key]" readonly>
      </mat-form-field>

    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Participants
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-card *ngFor="let parti of formData?.participants; let i = index">
      <mat-card-header>Participant {{i+1}}</mat-card-header>
      <mat-card-content>
        <div *ngFor="let item of ParticipantKeys">

          <mat-checkbox [(ngModel)]="parti[item.key]" *ngIf="item.type=='boolean'">{{item.key}}
          </mat-checkbox>

          <mat-form-field appearance="fill" [floatLabel]="true" *ngIf="item.type=='string'">
            <mat-label>{{item.key}}</mat-label>
            <input matInput placeholder="" [(ngModel)]="parti[item.key]">
          </mat-form-field>

        </div>
      </mat-card-content>
    </mat-card>

  </mat-expansion-panel>
</mat-accordion>

<button mat-button (click)="submit()" class="mat-primary">Submit</button>
