import {Training} from './training';

export class Registration {

  public acceptLanguage: boolean;
  public acceptPrivacy: boolean;
  public acceptTerms: boolean;
  public billingAddress: BillingAddress;
  public company: Company;
  public organizer: Organizer;
  public participants: Participant[];
  public training: TrainingShort;
  public bookingType: string;
  public fullCosts: string;
  public orderNumber: string;
  public trainingName: string;

  constructor(longTraining: Training) {
    this.acceptLanguage = false;
    this.acceptPrivacy = false;
    this.acceptTerms = false;
    this.bookingType = '';
    this.fullCosts = longTraining.costs + '€';
    this.orderNumber = '';
    this.trainingName = longTraining.title;
    this.billingAddress = new BillingAddress();
    this.company = new Company();
    this.training = new TrainingShort(longTraining);
    this.participants = [new Participant()];
  }

  public keys(): Array<{ key: string, type: string }> {
    const val = Object.keys(this).map((value, index) => {
      return {
        key: value,
        type: typeof this[value]
      };
    });
    return val;
  }
}


export class BillingAddress {
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  street: string;
  zip: string;

  constructor() {
    {
      this.city = 'München';
      this.country = 'Deutschland';
      this.firstName = 'Tester';
      this.lastName = 'Lasttester';
      this.street = 'Teststraße 42';
      this.zip = '12345';
    }
  }
  public keys(): Array<{ key: string, type: string }> {
    const val = Object.keys(this).map((value, index) => {
      return {
        key: value,
        type: typeof this[value]
      };
    });
    return val;
  }
}

export class Company {
  city: string;
  country: string;
  name: string;
  street: string;
  uid: string;
  zip: string;
  constructor() {
    {
      this.city = 'München';
      this.country = 'Frankreich';
      this.name = 'Testfirma OHG';
      this.street = 'ohg-test-straße 142';
      this.uid = 'FR12345';
      this.zip = '12345';
    }
  }
  public keys(): Array<{ key: string, type: string }> {
    const val = Object.keys(this).map((value, index) => {
      return {
        key: value,
        type: typeof this[value]
      };
    });
    return val;
  }
}

export interface Organizer {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export class Participant {
  birthdate: string;
  city: string;
  country: string;
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  phone: string;
  street: string;
  title: string;
  zip: string;

  constructor() {
    {
      this.birthdate = '01.01.2001';
      this.city = 'Berlin';
      this.country = 'Deutschland';
      this.email = 'teser@soul-surf.com';
      this.firstName = 'P-Tester';
      this.gender = 'Weiblich';
      this.lastName = 'P-Last';
      this.phone = '0123456789';
      this.street = 'P-Straße 24';
      this.title = 'Prof. Dr.';
      this.zip = '98765';
    }
  }
  public keys(): Array<{ key: string, type: string }> {
    const val = Object.keys(this).map((value, index) => {
      return {
        key: value,
        type: typeof this[value]
      };
    });
    return val;
  }
}

export interface Discount {
  description: string;
  discountedCostRaw: number;
  dueDate: Date;
  percentage: number;
}

export class TrainingShort {
  costsRaw: number;
  discount: Discount;
  end: Date;
  id: number;
  start: Date;
  title: string;

  constructor(longTraining: Training) {
    this.costsRaw = longTraining.costs;
    this.id = longTraining.id;
    this.title = longTraining.title;
    this.end = new Date(longTraining.end);
    this.start = new Date(longTraining.start);
    // this.discount = longTraining.costs;
  }
  public keys(): Array<{ key: string, type: string }> {
    const val = Object.keys(this).map((value, index) => {
      return {
        key: value,
        type: typeof this[value]
      };
    });
    return val;
  }
}

export interface RootObject {
  acceptLanguage: boolean;
  acceptPrivacy: boolean;
  acceptTerms: boolean;
  billingAddress: BillingAddress;
  bookingType: string;
  company: Company;
  fullCosts: string;
  orderNumber: string;
  organizer: Organizer;
  participants: Participant[];
  training: Training;
  trainingName: string;
}


