<h1>Trainings:</h1>

<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div fxLayout="row warp">


  <mat-card *ngFor="let training of trainings" class="example-card" fxFlex.lg="33" fxFlex.lt-lg="50" fxFlex.xs="100">
    <mat-card-header>
      <div class="example-header-image" mat-card-avatar></div>
      <mat-card-title>Title: {{training.title}}</mat-card-title>
      <mat-card-subtitle>City: {{training.city}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content [innerHTML]="training.type.description">


    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
    <mat-card-footer class="example-footer">
      <p>certificationEnabled: {{training.certificationEnabled}}</p>
      <p>certified: {{training.certified}}</p>
      <p>costs: {{training.costs}}</p>
      <p>start: {{training.start}}</p>
      <p>end: {{training.end}}</p>
      <p>id: {{training.id}}</p>
      <p>language: {{training.language}}</p>
      <p>remoteTraining: {{training.remoteTraining}}</p>
      <p>soldout: {{training.soldout}}</p>
      <mat-divider></mat-divider>
      <div>discount:
        <p> active: {{training.discount?.active}}</p>
        <p> description: {{training.discount?.description}}</p>
        <p> discountedCost: {{training.discount?.discountedCost}}</p>
        <p> percentage: {{training.discount?.percentage}}</p>
        <p> type: {{training.discount?.type}}</p>
        <p> validFrom: {{training.discount?.validFrom}}</p>
        <p> validUntil: {{training.discount?.validUntil}}</p>
      </div>

      <mat-divider></mat-divider>

      <p>trainer: {{training.trainers.join(', ')}}</p>

      <mat-divider></mat-divider>
      <div>
        type:
        <p>certified: {{training.type.certified}}</p>
        <p>id: {{training.type.id}}</p>
        <p>levels: {{myLevels(training.type.levels)}}</p>
        <p>name: {{training.type.name}}</p>
        <p>seoDescription: {{training.type.seoDescription}}</p>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
