<p>
  <mat-toolbar color="primary">
    <!--    <button aria-label="Example icon-button with menu icon" class="formData-icon" mat-icon-button>-->
    <!--      <mat-icon>menu</mat-icon>-->
    <!--    </button>-->
    <span>Gloger Trainings Test</span>
    <span class="example-spacer"></span>
    <button mat-button color="accent" [routerLink]="['']">
      <mat-icon>list</mat-icon>
      Trainings
    </button>
    <button mat-button color="accent" [routerLink]="['types']">
      <mat-icon>info</mat-icon>
      Typen
    </button>
    <button mat-button color="accent" [routerLink]="['order']">
      <mat-icon>shopping_bag</mat-icon>
      Bestellung
    </button>
  </mat-toolbar>
</p>


<div class="wrapper">
  <router-outlet></router-outlet>
</div>

