import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Participant, Registration} from '../classes/registration';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {Training} from '../classes/training';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  public formData: Registration;
  public RegistrationKeys: { key: string, type: string }[];
  public BillingAdressKeys: { key: string, type: string }[];
  public CompanyKeys: { key: string, type: string }[];
  public options: FormGroup;
  public hideRequiredControl = new FormControl(false);
  public floatLabelControl = new FormControl('auto');
  public loading: boolean;
  public training: Training;
  public TrainingKeys: Array<{ key: string; type: string }>;
  public ParticipantKeys: Array<{ key: string; type: string }>;

  constructor(private http: HttpClient, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.http.get('https://trainings-crm.borisgloger.com/api/website/trainings')
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((result: [Training]) => {
        this.training = result[0];
        console.log('result', this.training);
        this.init();
      });


  }

  private init(): void {
    this.formData = new Registration(this.training);
    console.log('Mykex', Object.keys(this.formData));
    console.log('MyForm', this.formData
    );
    this.RegistrationKeys = this.formData.keys();
    this.BillingAdressKeys = this.formData.billingAddress.keys();
    this.CompanyKeys = this.formData.company.keys();
    this.TrainingKeys = this.formData.training.keys();
    this.ParticipantKeys = new Participant().keys();

    this.options = this.fb.group({
      hideRequired: this.hideRequiredControl,
      floatLabel: this.floatLabelControl,
    });
  }

  public submit(): void {
    console.log('FORM', this.formData);

    // http://trainings-crm-beta.borisgloger.com/registrations

    this.http.post('https://trainings-crm-beta.borisgloger.com/api/website/registrations', this.formData)
      .subscribe((result: any) => {
        console.log('result', result);
      });
  }
}
