import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ResttesterComponent} from './resttester/resttester.component';
import {TrainingtypesComponent} from './trainingtypes/trainingtypes.component';
import {OrderComponent} from './order/order.component'; // CLI imports router

const routes: Routes = [
  {path: '', component: ResttesterComponent},
  {path: 'types', component: TrainingtypesComponent},
  {path: 'order', component: OrderComponent},
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
