<h1>Typen:</h1>
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div fxLayout="row warp">
  <mat-card *ngFor="let type of types" class="example-card" fxFlex.lg="33" fxFlex.lt-lg="50" fxFlex.xs="100">
    <mat-card-header>
      <div class="example-header-image" mat-card-avatar></div>
      <mat-card-title>Name: {{type.name}}</mat-card-title>
    </mat-card-header>
    <mat-card-content [innerHTML]="type.description">
    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
    <mat-card-footer class="example-footer">
      <p>certified: {{type.certified}}</p>
      <p>id: {{type.id}}</p>
      <p>levels: {{myLevels(type.levels)}}</p>
      <p>name: {{type.name}}</p>
      <p>seoDescription: {{type.seoDescription}}</p>
    </mat-card-footer>
  </mat-card>
</div>
