import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Training} from '../classes/training';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-resttester',
  templateUrl: './resttester.component.html',
  styleUrls: ['./resttester.component.scss']
})
export class ResttesterComponent implements OnInit {
  options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: 'body' | 'events' | 'response',
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  };
  loading = false;
  trainings: [Training];
  listComp: [
    'certificationEnabled',
    'certified',
    'costs',
    'start',
    'end',
    'id',
    'language',
    'remoteTraining',
    'soldout'
  ];

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.http.get('https://trainings-crm.borisgloger.com/api/website/trainings')
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((result: [Training]) => {
        console.log('result', result);
        this.trainings = result;
      });
  }

  myLevels(levels: [{ id: number, name: string }]): string {
    let levelstring = '';
    levels.forEach(value => {
      levelstring += value.id + ': ' + value.name + ', ';
    });
    return levelstring.substring(0, levelstring.length - 2);
  }
}
